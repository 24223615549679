/* import __COLOCATED_TEMPLATE__ from './status.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';

interface Args {
  status?: string;
}

export default class Status extends Component<Args> {
  get stateType() {
    return this.args.status || 'missing';
  }

  get stateCssClass() {
    return `o__${this.stateType.replace('_', '-')}`;
  }

  get stateTranslationKey() {
    switch (this.stateType) {
      case 'missing-article':
        return `articles.list.content_status.badge.missing_article`;
      case 'missing-title':
        return `articles.list.content_status.badge.missing_title`;
      case 'published':
        return `articles.list.content_status.badge.published_label`;
      case 'draft':
        return `articles.list.content_status.badge.draft_label`;
      case 'missing':
      default:
        return `articles.list.content_status.badge.missing_label`;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Collections::Tree::Cell::Status': typeof Status;
    'articles/site/collections/tree/cell/status': typeof Status;
  }
}
