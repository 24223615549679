/* import __COLOCATED_TEMPLATE__ from './answer-list.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AnswerList extends Component {
  @service router;
  @service intercomEventService;
  @service appService;
  @service intl;

  @tracked app = this.appService.app;

  get filteredAnswers() {
    let filteredAnswers = this.args.answers;

    if (this.args.languageCode) {
      filteredAnswers = filteredAnswers.filter(
        (answer) => answer.languageCode === this.args.languageCode,
      );
    }

    if (this.args.tagIds && !!this.args.tagIds.length) {
      filteredAnswers = filteredAnswers.filter((answer) => {
        let answerTagIds = answer.tags.toArray().map((tag) => tag.id.toString());
        return answerTagIds.some((tagId) => this.args.tagIds.includes(tagId));
      });
    }

    return filteredAnswers;
  }

  get filteredUnderperformingAnswers() {
    return this.filteredAnswers.filter((answer) => answer.stats?.isUnderperforming);
  }

  get hideUnderperformingAnswersTab() {
    if (this.args.hideUnderperformingAnswersTab === true) {
      return true;
    }

    let hasUnderperformingAnswers = this.args.answers.any(
      (answer) => answer.stats?.isUnderperforming,
    );
    return !hasUnderperformingAnswers;
  }

  get displayedAnswers() {
    if (this.args.status === 'all') {
      return this.filteredAnswers;
    } else if (this.args.status === 'underperforming') {
      return this.filteredUnderperformingAnswers;
    } else {
      return this.filteredAnswers.filter((answer) => answer.status === this.args.status);
    }
  }

  get answersCountByState() {
    let initialCount = {
      all: this.filteredAnswers.length,
      live: 0,
      draft: 0,
      paused: 0,
      underperforming: this.filteredUnderperformingAnswers.length,
    };
    return this.filteredAnswers.reduce(function (counts, answer) {
      counts[answer.status] += 1;
      return counts;
    }, initialCount);
  }

  get tags() {
    return this.appService.app.tags.reject((tag) => tag.archived);
  }

  get emptyTagsEntry() {
    return { text: this.intl.t('custom-answers.answer-tagging-filter.no_tags'), value: 0 };
  }

  @action
  createAnswer() {
    if (this.args.createAnswer) {
      this.args.createAnswer();
    } else {
      this.router.transitionTo('apps.app.automation.resolution-bot.answers.new', {
        queryParams: {
          language: this.args.languageCode,
        },
      });
    }
  }

  @action
  filterByLanguage(languageCode) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'language_selected',
      object: 'answers',
      context: this.args.trackingContext,
      language_code: languageCode,
    });
    this.args.filterByLanguage(languageCode);
  }

  @action
  onStatusChange(status) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'filter_toggled',
      object: 'answers',
      context: this.args.trackingContext,
      filter_value: status,
    });
    this.args.filterByStatus(status);
  }

  @action
  onTagFilterChange(_, tagIds) {
    this.args.filterByTagIds(tagIds);
  }

  @action
  onUpdateDateRange(range) {
    this.args.filterByDateRange(range.start, range.end);
  }
}
