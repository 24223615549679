/* import __COLOCATED_TEMPLATE__ from './collections-tree.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import Component from '@glimmer/component';
import {
  type DropActionType,
  Tree,
  type TreeItem,
  type TreeParent,
} from 'embercom/objects/tree-list';
import { inject as service } from '@ember/service';
import {
  buildNewArticleNode,
  buildNewCollectionNode,
  canDropItem,
  didDropItem,
  sortContent,
  updateCountForCollectionAndAncestors,
  indexForDropInside,
  focusOnItem,
  TIMEOUTS,
  checkMaxLevel,
  MAX_LEVEL,
} from './collection-tree-helper';
import {
  type ArticleSummary,
  type ArticleModel,
  DataTypes,
  type Collection,
  type DataObject,
} from './types';
import {
  CAN_MANAGE_ARTICLES_PERMISSION,
  MAX_ARTICLES_IN_A_COLLECTION,
} from 'embercom/lib/articles/constants';
import type Store from '@ember-data/store';
import ArticleGroup from 'embercom/models/articles/article-group';
import type EmberObject from '@ember/object';
import type IntlService from 'embercom/services/intl';
import type ArticleMultilingual from 'embercom/models/article-multilingual';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { all } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';

interface Args {
  collections: Array<Collection>;
  articleSummaries: Array<ArticleSummary>;
  locale: string;
  defaultLocale: string;
  selectedCollection: Collection;
  deleteCollection?: (collection: Collection) => Promise<boolean>;
  registerTree: (tree: any) => void;
  getContainerReference: () => HTMLElement;
  helpCenterId: string;
}

let SCROLL_PARAMS = {
  step: 7,
  bottomThreshold: 150,
  topThreshold: 150,
};

export default class CollectionsTree extends Component<Args> {
  root = this.args.collections.find((collection: Collection) => collection.get('isHome'));
  tree = new Tree(
    [],
    {
      canDragItem: (item: TreeItem) => {
        let mainCollections = item.tree.children.map(
          (i) => (i.dataObject as DataObject).content as Collection,
        );
        return mainCollections.every((c) => !c.read_only);
      },
      canDropItem,
      willDropItem: (item: TreeItem, newParent: TreeParent, _: DropActionType) => {
        let shouldContinue = true;
        let dataObject = item.dataObject as DataObject;
        if (dataObject.type === DataTypes.collection) {
          shouldContinue = checkMaxLevel(newParent, item);
        }
        if (!shouldContinue) {
          this.notificationsService.notifyError(
            this.intl.t('articles.collections.tree.notification.error-max-level', {
              maxLevel: MAX_LEVEL + 1,
            }),
          );
        }
        return shouldContinue;
      },
      didDropItem: (item: TreeItem, oldParent: TreeParent, dropActionTye: DropActionType) =>
        this.root &&
        didDropItem(
          item,
          this.root,
          oldParent,
          dropActionTye,
          this.reorderCollection,
          this.moveCollection,
          this.moveArticle,
        ),
      indexForDropInside,
    },
    {
      insertionPoint: {
        classes: 'w-full rounded transition-all duration-200 ease-in-out rounded',
        activeClasses: 'bg-sky-darkest border-2 border-dotted border-blue',
      },
      childList: {
        classes: '',
      },
      listItem: {
        classes:
          'flex flex-row items-center rounded border-transparent hover:bg-white transition-colors duration-200 ease-in-out',
        activeClasses: 'bg-sky-darkest',
      },
      draggableContainer: {
        classes: 'flex flex-col',
      },
      dragProperties: {
        minDistanceInPX: 20,
        onDrag: (y: number) => {
          let containerReference = this.args.getContainerReference();
          if (containerReference) {
            if (y > window.innerHeight - SCROLL_PARAMS.bottomThreshold) {
              containerReference.scrollTo({
                left: containerReference.scrollLeft,
                top: containerReference.scrollTop + SCROLL_PARAMS.step,
              });
            } else if (y < SCROLL_PARAMS.topThreshold) {
              containerReference.scrollTo({
                left: containerReference.scrollLeft,
                top: containerReference.scrollTop - SCROLL_PARAMS.step,
              });
            }
          }
        },
        shortDraggingImage: true,
      },
    },
  );
  expandedCollections = new Set<string>();
  @service notificationsService: any;
  @service declare intl: IntlService;
  @service declare permissionsService: any;
  @service declare store: Store;
  @service declare appService: any;
  @service declare intercomConfirmService: IntercomConfirmService;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.initializeExpandedCollections();
    this.initializeTree();
    this.args.registerTree(this.addRootCollection.bind(this));
  }

  get app() {
    return this.appService.app;
  }

  @action
  async addRootCollection() {
    let newRootCollection = await this.addCollection(undefined);
    this.tree.children.addObject(newRootCollection);
    later(() => focusOnItem(newRootCollection), TIMEOUTS.insertion);
  }

  initializeExpandedCollections() {
    let selectedCollection = this.args.selectedCollection;
    if (!selectedCollection) {
      return;
    }

    this.expandedCollections.add(selectedCollection.get('id'));

    let currentCollection = selectedCollection.get('parent');
    while (currentCollection && currentCollection.get('id') !== 'home') {
      this.expandedCollections.add(currentCollection.get('id'));
      currentCollection = currentCollection.get('parent') as Collection;
    }
  }

  initializeTree() {
    let collectionIdArticleMap = this.collectionToArticlesMap();
    if (this.root) {
      let rootChildren = this.root.children
        .toArray()
        .filter((child: Collection) => child.helpCenterId === this.args.helpCenterId)
        .sort(sortContent);
      this.tree.children = rootChildren.map((child: any) =>
        this.exploreCollectionTree(child as Collection, this.tree, collectionIdArticleMap, 0),
      );
    }
  }

  private collectionToArticlesMap() {
    return this.args.articleSummaries.reduce((acc, element: ArticleSummary) => {
      element.parent_collections.forEach((collectionId: number) => {
        let collection = String(collectionId);
        let articles = acc.get(collection) || [];
        articles.push(element);
        acc.set(collection, articles);
      });
      return acc;
    }, new Map<string, Array<ArticleSummary>>());
  }

  @action
  async addCollection(id: string | undefined, level = 0) {
    let newChildren = await this.createCollectionForParent(id, this.args.helpCenterId);
    return buildNewCollectionNode(
      this.tree,
      newChildren,
      level,
      () => this.args.locale,
      this.addCollectionToParent,
      this.addArticlesToCollection,
      this.listAddableArticles,
      this.removeCollection,
      this.saveCollection,
      false,
      true,
    );
  }

  exploreCollectionTree(
    node: Collection,
    tree: Tree,
    groupIdArticleMap: Map<string, Array<ArticleSummary>>,
    level: number,
  ): TreeItem {
    let articleNodes: Array<TreeItem> = [];
    let articles = groupIdArticleMap.get(node.id);
    if (articles) {
      let articlesInOrder = [...articles];
      articlesInOrder.sort(sortContent);
      articleNodes = articlesInOrder.map((article) => {
        article.collection = node;
        return buildNewArticleNode(
          tree,
          article,
          level + 1,
          () => this.args.locale,
          () => this.args.defaultLocale,
          this.removeArticleFromCollection,
        );
      });
    }
    let collectionsInOrder = node.children.toArray();
    collectionsInOrder.sort(sortContent);
    let childrenNodes = collectionsInOrder.map((childNode: Collection) =>
      this.exploreCollectionTree(childNode, tree, groupIdArticleMap, level + 1),
    );
    let isExpanded = this.expandedCollections.has(node.id);
    let parentNode = buildNewCollectionNode(
      tree,
      node,
      level,
      () => this.args.locale,
      this.addCollectionToParent,
      this.addArticlesToCollection,
      this.listAddableArticles,
      this.removeCollection,
      this.saveCollection,
      isExpanded,
    );
    parentNode.addChildren(articleNodes);
    parentNode.addChildren(childrenNodes);
    return parentNode;
  }

  @action
  async addCollectionToParent(parentItem: TreeItem) {
    let parentDataObject = parentItem.dataObject as DataObject;
    if (parentDataObject.type === DataTypes.collection) {
      let collection = parentDataObject.content as Collection;
      let newChildren = await this.addCollection(collection.id, parentDataObject.level + 1);
      parentItem.addChildren([newChildren]);
      if (!parentItem.isExpanded) {
        parentItem.isExpanded = true;
        later(() => focusOnItem(newChildren), TIMEOUTS.expansionAndInsertion);
      } else {
        later(() => focusOnItem(newChildren), TIMEOUTS.insertion);
      }
    }
  }

  @action
  async addArticlesToCollection(articles: Array<any>, collectionItem: TreeItem) {
    let collectionDataObject = collectionItem.dataObject as DataObject;
    if (collectionDataObject.type === DataTypes.collection) {
      let collection = collectionDataObject.content as Collection;
      let updatedArticles = await taskFor(this.addArticlesToCollectionTask).perform(
        articles,
        collection,
      );
      if (!updatedArticles) {
        return;
      }
      updatedArticles.sort(sortContent);
      let newArticleNodes: Array<TreeItem> = updatedArticles.map((article: ArticleMultilingual) => {
        let articleSummary = {
          id: article.get('id'),
          public: !article.hasRestrictedAudience,
          collection,
          article_group_id: collection.get('id'),
          order: article.order,
          locales: article.articleContents.reduce((acc: any, element: any) => {
            acc[element.locale] = {
              title: element.title,
              summary: element.summary,
              state: element.state,
            };
            return acc;
          }, {}),
          read_only: article.readOnly,
          parent_collections: [], // to be addressed next in adding articles to multiple collections from the collections page
        };
        return buildNewArticleNode(
          this.tree,
          articleSummary,
          collectionDataObject.level + 1,
          () => this.args.locale,
          () => this.args.defaultLocale,
          this.removeArticleFromCollection,
        );
      });
      let nbSiglingArticles =
        collectionItem.children?.filter((child) => {
          let dataObject = child.dataObject as DataObject;
          return dataObject.type === DataTypes.article;
        }).length || 0;
      newArticleNodes.forEach((newArticleNode, index) => {
        collectionItem.children?.insertAt(index + nbSiglingArticles, newArticleNode);
        newArticleNode.parent = collectionItem as TreeParent;
      });
      updateCountForCollectionAndAncestors(collection, newArticleNodes.length);
      if (!collectionItem.isExpanded) {
        collectionItem.isExpanded = true;
        later(() => focusOnItem(collectionItem, false), TIMEOUTS.expansionAndInsertion);
      } else {
        later(() => focusOnItem(collectionItem, false), TIMEOUTS.insertion);
      }
    }
  }

  @action
  async removeCollection(collectionItem: TreeItem) {
    let dataObject = collectionItem.dataObject as DataObject;
    if (dataObject.type === DataTypes.collection) {
      let collection = dataObject.content as Collection;
      let nbArticles = collection.get('count');
      let hasSucceded = await this.deleteCollection(collection);
      if (hasSucceded) {
        collectionItem.parent.children?.removeObject(collectionItem);
      }
      updateCountForCollectionAndAncestors(collection.get('parent'), -nbArticles);
    }
  }

  @action
  async removeArticleFromCollection(articleItem: TreeItem) {
    let dataObject = articleItem.dataObject as DataObject;
    if (dataObject.type !== DataTypes.article) {
      return;
    }

    let article = dataObject.content as ArticleSummary;
    let currentCollection = article.collection;
    let isRemoved = await this.removeArticleFromCollectionMultiple(article, currentCollection);
    if (isRemoved) {
      articleItem.removeFromParent();
    }

    while (currentCollection && !currentCollection.get('isHome')) {
      currentCollection.set('count', currentCollection.get('count') - 1);
      currentCollection = currentCollection.get('parent') as Collection;
    }
  }

  @action
  async createCollectionForParent(parentCollectionId = 'home', helpCenterId: string) {
    await this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION);
    let parentCollection = this.store.peekRecord('articles/article-group', parentCollectionId);
    // @ts-ignore
    let newCollection = ArticleGroup.createNewFolder(
      parentCollection,
      this.intl.t('articles.collections.new-collection-placeholder'),
      helpCenterId,
    );
    return await newCollection.save();
  }

  @action
  async listAddableArticles(): Promise<Array<ArticleMultilingual>> {
    let allArticles = (await this.store.query('article-multilingual', {
      disable_pagination: true,
    })) as unknown as ArticleMultilingual[];

    return allArticles.filter(
      (article: ArticleMultilingual) => !article.helpCenterIds.includes(this.args.helpCenterId),
    );
  }

  @task
  *addArticlesToCollectionTask(
    articles: Array<ArticleMultilingual>,
    collection: Collection,
  ): TaskGenerator<any> {
    try {
      let saveTasks = [];
      for (let article of articles) {
        saveTasks.push(taskFor(this.addArticleToCollection).perform(article, collection));
      }
      return yield all(saveTasks);
    } catch (err) {
      if (err.jqXHR?.responseJSON?.key === 'max_collection_articles_reached') {
        this.notificationsService.notifyError(
          this.intl.t('articles.collections.add_article.max_articles_reached', {
            limit: MAX_ARTICLES_IN_A_COLLECTION,
          }),
        );
      } else {
        this.notificationsService.notifyError(
          this.intl.t('articles.collections.add_article.error'),
        );
      }
      return false;
    }
  }

  @task({ enqueue: true, maxConcurrency: 1 })
  *addArticleToCollection(
    article: ArticleMultilingual,
    collection: Collection,
  ): TaskGenerator<ArticleMultilingual> {
    article.setProperties({
      folder: collection,
      order: null,
    });
    return yield article.save();
  }

  @action
  async saveCollection(collection: Collection) {
    await this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION);
    // @ts-ignore
    if (collection.hasDirtyAttributes) {
      await collection.save();
    }
  }

  @action
  async deleteCollection(collection: Collection): Promise<boolean> {
    // Needed for testing
    if (this.args.deleteCollection && typeof this.args.deleteCollection === 'function') {
      return this.args.deleteCollection(collection);
    }

    await this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION);
    let confirmOptions = {
      title: this.intl.t('articles.collections.confirm_delete.collection.title'),
      confirmButtonText: this.intl.t(
        'articles.collections.confirm_delete.collection.confirm_button',
      ),
      body:
        collection.count > 0
          ? this.intl.t('articles.collections.confirm_delete.collection.body_with_article')
          : this.intl.t('articles.collections.confirm_delete.collection.body_without_article'),
      cancelButtonText: this.intl.t('articles.collections.confirm_delete.cancel'),
      primaryButtonType: 'primary-destructive',
    };
    let isConfirmed = await this.intercomConfirmService.confirm(confirmOptions);
    if (!isConfirmed) {
      return false;
    }
    collection.destroyRecord();
    return true;
  }

  @action
  async moveCollection(
    collection: Collection,
    parentCollection: Collection,
    newPosition: number,
    positionMap: any,
  ) {
    await this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION);
    collection.parent = parentCollection;
    if (!parentCollection.isHome) {
      collection.icon = 'folder';
    }
    await collection.save();
    await collection.reorder(newPosition, Object.fromEntries(positionMap), this.args.helpCenterId);
    return collection;
  }

  @action
  async reorderCollection(collection: Collection, newPosition: number, positionMap: any) {
    await this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION);
    await collection.reorder(newPosition, Object.fromEntries(positionMap), this.args.helpCenterId);
    return collection;
  }

  get confirmOptions() {
    return {
      title: this.intl.t('articles.collections.confirm_delete.article.title'),
      confirmButtonText: this.intl.t('articles.collections.confirm_delete.article.confirm_button'),
      body: this.intl.t('articles.collections.confirm_delete.article.body'),
      primaryButtonType: 'primary-destructive',
    };
  }

  @action
  async removeArticleFromCollectionMultiple(
    articleSummary: ArticleSummary,
    currentCollection: Collection,
  ) {
    await this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION);

    try {
      let isConfirmed = await this.intercomConfirmService.confirm(this.confirmOptions);

      if (!isConfirmed) {
        return false;
      }

      let article = await this.store.findRecord('article-multilingual', articleSummary.id);
      let oldCollections = await article.inCollections; // await needed as we may need to fetch collections from other help centers
      let newCollections = oldCollections.filter(
        (collection: ArticleGroup) => collection.id !== currentCollection.id,
      );

      article.set('inCollections', newCollections);
      let home = await this.store.findRecord('articles/article-group', 'home');
      let newFolder = newCollections.length > 0 ? newCollections.firstObject : home;
      article.set('folder', newFolder);

      await article.get('updateArticlesMultiple').perform();

      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  @action
  async moveArticle(
    articleSummary: ArticleModel,
    collection: Collection,
    order: number,
    oldCollection: Collection,
  ) {
    await this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION);

    let article: EmberObject & ArticleModel = await this.store.findRecord(
      'article-multilingual',
      articleSummary.id,
    );
    if (oldCollection) {
      article.set('folder', collection);
      await article.save();
    }
    await article.reorder(order, this.args.helpCenterId);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Collections::Tree::CollectionsTree': typeof CollectionsTree;
    'articles/site/collections/tree/collections-tree': typeof CollectionsTree;
  }
}
