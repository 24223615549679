/* import __COLOCATED_TEMPLATE__ from './subscription-builder.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import confetti from 'canvas-confetti';
import { dropTask, keepLatestTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import ENV from 'embercom/config/environment';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import moment from 'moment-timezone';
import {
  BILLING_PERIODS,
  FIN_AI_COPILOT_BASE_ID,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
} from 'embercom/lib/billing';
import type IntlService from 'embercom/services/intl';
import { DEFAULT_REQUEST_PARAMS } from 'embercom/services/quote-service';
// @ts-ignore
import { globalRef } from 'ember-ref-bucket';

import { type TaskGenerator } from 'ember-concurrency';
import type Plan from 'embercom/models/plan';
import type QuoteService from 'embercom/services/quote-service';
interface Args {
  contract: any;
  openSection: string;
  featureName: string;
  planId: number;
  additionalCoreSeatsNumber: number | null;
  newSettings?: boolean;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export enum ModalTypes {
  Upgrade = 'upgrade',
  RemovingProducts = 'removing-products',
  RemoveYearlySeats = 'removing-yearly-seats',
  AddingMonthlySeats = 'adding-monthly-seats',
  AddingTooManyCopilotSeats = 'adding-too-many-copilot-seats',
  RemovingMonthlySeats = 'removing-monthly-seats',
  DowngradePlan = 'downgrading-plan',
  WaitingForSubscriptionUpdate = 'waiting-for-subscription-update',
  UpgradedWithoutAppUpdate = 'upgraded-without-app-update',
}

const FIN_AI_COPILOT_BASE_ID_NUM = Number(FIN_AI_COPILOT_BASE_ID);
const PROACTIVE_SUPPORT_PLUS_BASE_ID_NUM = Number(PROACTIVE_SUPPORT_PLUS_BASE_ID);

export default class SubscriptionBuilder extends Component<Signature> {
  @service declare appService: any;
  @service declare purchaseAnalyticsService: any;
  @service declare quoteService: QuoteService;
  @service declare intl: IntlService;
  @service declare modelDataCacheService: any;
  @service declare notificationsService: any;
  @service declare customerService: any;
  @service declare store: Store;
  @service declare annualSubscriptionUpdateService: any;
  @service declare router: any;
  @tracked openSectionId = this.args.openSection ?? 'plans';
  @tracked selectedPlanId = this.inSubscriptionPlan.idAsNumber;
  @tracked addOnIdsToAdd: Array<number> = [...this.currentAddonIds].compact() || [];
  @tracked seatsToAddToContract = this.args.additionalCoreSeatsNumber ?? 0;
  @tracked copilotSeatsToAddToContract = 0;
  @tracked seatNumber = this.currentContractedSeats;
  @tracked copilotSeatNumber = this.currentContractedCopilotSeats;
  @tracked showConfettiCanvas = false;
  @tracked currentTotalWithMonthlyOverages = 0;
  @tracked predictedTotalWithMonthlyOverages = 0;
  @tracked currentContractedTotal = 0;
  @tracked predictedContractedTotal = 0;
  @tracked modalType = '';
  @tracked showCloseIcon = true;
  @globalRef('subscription-management-confetti-canvas') declare confettiCanvas?: HTMLCanvasElement;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    taskFor(this.getQuote).perform();

    // highlight plan if feature and planid is passed in params
    let highlightPlan = this.app.allPlansOnPricingModel.find(
      (plan: Plan) => plan.idAsNumber === Number(this.args.planId),
    );
    if (highlightPlan) {
      if (!highlightPlan.product.addon) {
        this.selectedPlanId = Number(this.args.planId);
      } else {
        this.addOnIdsToAdd = [...this.addOnIdsToAdd, Number(this.args.planId)];
      }
    }
  }

  @action
  setCurrentTotalWithMonthlyOverages(value: number) {
    this.currentTotalWithMonthlyOverages = value;
  }

  @action
  setPredictedTotalWithMonthlyOverages(value: number) {
    this.predictedTotalWithMonthlyOverages = value;
  }

  @action
  setCurrentContractedTotal(value: number) {
    this.currentContractedTotal = value;
  }

  @action
  setPredictedContractedTotal(value: number) {
    this.predictedContractedTotal = value;
  }

  get billingPeriod() {
    return BILLING_PERIODS.Annual;
  }

  get loading() {
    return this.quoteService.loading;
  }

  get renewalDate() {
    return this.customerService.selfServeRenewalDate;
  }

  get formattedRenewalDate() {
    return moment(this.renewalDate).format('ll');
  }

  get app() {
    return this.appService.app;
  }

  get selectedPlan() {
    return this.app.allPlansOnPricingModel.find(
      (plan: Plan) => plan.idAsNumber === this.selectedPlanId,
    );
  }

  get selectedPlanName() {
    return this.intl.t(this.selectedPlan.marketingNameTranslationKey);
  }

  // seats
  get adminsWithCoreSeats() {
    return this.quoteService.fullSeatCount;
  }

  get adminsWithCopilotSeats() {
    return this.quoteService.copilotSeatCount;
  }

  get newOverageSeats() {
    return this.currentOverageSeats - this.seatsToAddToContract;
  }

  get newOverageCopilotSeats() {
    return this.currentCopilotOverageSeats - this.copilotSeatsToAddToContract;
  }

  private calculateCurrentSeatOverage(currentContractedSeats: number, adminWithSeat: number) {
    return currentContractedSeats < adminWithSeat ? adminWithSeat - currentContractedSeats : 0;
  }

  get currentOverageSeats() {
    return this.calculateCurrentSeatOverage(this.currentContractedSeats, this.adminsWithCoreSeats);
  }

  get copilotPlan() {
    return this.app.allPlansOnPricingModel.find(
      (plan: Plan) => plan.idAsNumber === FIN_AI_COPILOT_BASE_ID_NUM,
    );
  }

  get copilotIsOnNonGraduatingTrial() {
    return this.copilotPlan.activeTrial && !this.copilotPlan.activeTrialIsGraduating;
  }

  get showCopilotContractedSeatSelector() {
    if (!this.copilotIsOnNonGraduatingTrial) {
      return true;
    }
    return this.copilotSeatsToAddToContract > 0;
  }

  get currentCopilotOverageSeats() {
    if (this.copilotIsOnNonGraduatingTrial) {
      return 0;
    }
    return this.calculateCurrentSeatOverage(
      this.currentContractedCopilotSeats,
      this.adminsWithCopilotSeats,
    );
  }

  private calculateContractedSeats(currentContractedSeats: number, seatsToAddToContract: number) {
    return currentContractedSeats + seatsToAddToContract;
  }

  get newContractedSeats() {
    return this.calculateContractedSeats(this.currentContractedSeats, this.seatsToAddToContract);
  }

  get newContractedCopilotSeats() {
    return this.calculateContractedSeats(
      this.currentContractedCopilotSeats,
      this.copilotSeatsToAddToContract,
    );
  }

  get currentContractedSeats(): number {
    return this.args.contract.contractUsageLimits.core_seat_count;
  }

  get selectedCopilotAddon() {
    return (
      this.copilotSeatsToAddToContract > 0 &&
      !this.addOnIdsToAdd.includes(FIN_AI_COPILOT_BASE_ID_NUM)
    );
  }

  get currentContractedCopilotSeats(): number {
    return this.args.contract.contractUsageLimits.copilot_seat_count || 0;
  }

  // plans
  get inSubscriptionPlan() {
    return this.app.allPlansOnPricingModel.find(
      (plan: Plan) => plan.billableCustomerPlan && !plan.product.addon,
    );
  }

  get availablePlans() {
    return this.app.allPlansOnPricingModel.filter(
      (plan: Plan) => !plan.product.addon && plan.selfServe,
    );
  }

  get currentAddonIds() {
    return (
      this.app.allPlansOnPricingModel
        .filter((plan: Plan) => plan.billableCustomerPlan && plan.product.addon)
        .map((plan: Plan) => plan.idAsNumber) || []
    );
  }

  get availableAddons() {
    return this.app.allPlansOnPricingModel.filter(
      // we removed Copilot from the list as people will buy Copilot seats from the seats accordion for now
      (plan: Plan) => plan.product.addon && plan.selfServe && plan.id !== FIN_AI_COPILOT_BASE_ID,
    );
  }

  /// basket attributes
  get currentHeader() {
    return this.intl.t(`billing.annual-manage-subscription.current-heading`);
  }

  get predictedHeader() {
    return this.intl.t(`billing.annual-manage-subscription.predict-heading`);
  }

  get subscriptionUpdated() {
    let plansHaveDiff = this.selectedPlanId !== this.inSubscriptionPlan.idAsNumber;
    let addonsHaveDiff = this.addOnIdsToAdd.length > this.currentAddonIds.length;
    let seatsHaveDiff = this.seatsToAddToContract > 0;
    let copilotSeatsHaveDiff = this.copilotSeatsToAddToContract > 0;
    return plansHaveDiff || addonsHaveDiff || seatsHaveDiff || copilotSeatsHaveDiff;
  }

  private calculateSeatOverageSavings(
    seatPrice: number,
    discountedSeatPrice: number,
    overageSeats: number,
  ) {
    return (seatPrice - discountedSeatPrice) * overageSeats * BILLING_PERIODS.Annual;
  }

  //prices
  get overageSavings() {
    return this.calculateSeatOverageSavings(
      this.fullSeatPrice,
      this.discountedSeatPrice,
      this.newOverageSeats,
    );
  }

  get copilotOverageSavings() {
    return this.calculateSeatOverageSavings(
      this.copilotSeatPrice,
      this.discountedCopilotSeatPrice,
      this.newOverageCopilotSeats,
    );
  }

  get priceDifferenceWithMonthlyOverages() {
    return this.predictedTotalWithMonthlyOverages - this.currentTotalWithMonthlyOverages;
  }

  get formattedPriceDifferenceWithMonthlyOverages() {
    let prefix = this.priceDifferenceWithMonthlyOverages > 0 ? '+' : '';
    return `${prefix}${this.quoteService.formatPrice(this.priceDifferenceWithMonthlyOverages)}`;
  }

  get contractedPriceDifference() {
    return this.predictedContractedTotal - this.currentContractedTotal;
  }

  get contractedPriceEstimate() {
    let renewalDay = moment(this.renewalDate).startOf('day');
    let remainingDaysInContract = renewalDay.diff(moment().startOf('day'), 'days', true);
    let refundAmount = ((this.currentContractedTotal * 12) / 365) * remainingDaysInContract;
    let chargeAmount = ((this.predictedContractedTotal * 12) / 365) * remainingDaysInContract;
    return this.quoteService.formatPrice(Math.trunc(chargeAmount - refundAmount));
  }

  quote(id: number) {
    return this.quoteService.getQuoteById([id]);
  }

  get fullSeatPrice() {
    return this.quote(this.selectedPlanId)?.fullSeatPrice(this.selectedPlanId) || 0;
  }

  get copilotSeatPrice() {
    return (
      this.quote(FIN_AI_COPILOT_BASE_ID_NUM)?.fullSeatPrice?.(
        FIN_AI_COPILOT_BASE_ID_NUM,
        Metric.copilot_seat_count,
      ) ?? 0
    );
  }

  get discountedCopilotSeatPrice() {
    return (
      this.quote(FIN_AI_COPILOT_BASE_ID_NUM)?.discountedAnnualSeatPrice?.(
        FIN_AI_COPILOT_BASE_ID_NUM,
        Metric.copilot_seat_count,
      ) ?? 0
    );
  }

  get discountedSeatPrice() {
    return this.quote(this.selectedPlanId)?.discountedAnnualSeatPrice?.(this.selectedPlanId) || 0;
  }

  //modal
  get modalHeader() {
    return this.intl.t(`billing.annual-manage-subscription.modal.${this.modalType}.header`);
  }

  get modalBody() {
    switch (this.modalType) {
      case ModalTypes.Upgrade:
        return this.intl.t(`billing.annual-manage-subscription.modal.${this.modalType}.body`, {
          priceEstimate: this.contractedPriceEstimate,
          renewalDate: this.formattedRenewalDate,
          htmlSafe: true,
        });
      // Why don't these work as a combined case?
      case ModalTypes.RemovingProducts:
        return this.intl.t(`billing.annual-manage-subscription.modal.${this.modalType}.body`, {
          renewalDate: this.formattedRenewalDate,
          htmlSafe: true,
        });
      case ModalTypes.RemoveYearlySeats:
        return this.intl.t(`billing.annual-manage-subscription.modal.${this.modalType}.body`, {
          renewalDate: this.formattedRenewalDate,
          htmlSafe: true,
        });
      case ModalTypes.DowngradePlan:
        return this.intl.t(`billing.annual-manage-subscription.modal.${this.modalType}.body`, {
          renewalDate: this.formattedRenewalDate,
          htmlSafe: true,
        });
      case ModalTypes.RemovingMonthlySeats:
        return this.intl.t(`billing.annual-manage-subscription.modal.${this.modalType}.body`, {
          teammateUrl: this.router.urlFor('apps.app.settings.teammates'),
          htmlSafe: true,
        });
      case ModalTypes.WaitingForSubscriptionUpdate:
        return this.intl.t(`billing.annual-manage-subscription.modal.${this.modalType}.body`, {
          teammateUrl: this.router.urlFor('apps.app.settings.teammates'),
          htmlSafe: true,
        });
      case ModalTypes.UpgradedWithoutAppUpdate:
        return this.intl.t(`billing.annual-manage-subscription.modal.${this.modalType}.body`, {
          teammateUrl: this.router.urlFor('apps.app.settings.teammates'),
          htmlSafe: true,
        });
      default:
        return this.intl.t(`billing.annual-manage-subscription.modal.${this.modalType}.body`, {
          htmlSafe: true,
        });
    }
  }

  get modalConfirm() {
    return this.intl.t(`billing.annual-manage-subscription.modal.${this.modalType}.confirm`);
  }

  get modalLabel() {
    if (taskFor(this.upgradeNow).isRunning) {
      return this.intl.t(`billing.annual-manage-subscription.just-a-moment`);
    } else {
      return this.modalConfirm;
    }
  }

  get modalConfirmDisabled() {
    return taskFor(this.upgradeNow).isRunning;
  }

  @action
  showConfirmationModal() {
    this.showModal(ModalTypes.Upgrade);
  }

  @action
  showModal(modalType: ModalTypes) {
    this.modalType = modalType;
    this.sendAnalyticsEvent(`modal-${this.modalType}`, 'opened');
  }

  @action
  modalAction() {
    switch (this.modalType) {
      case ModalTypes.Upgrade:
        this.sendAnalyticsEvent(`modal-${this.modalType}`, 'confirm');
        taskFor(this.upgradeNow).perform();
        break;
      case ModalTypes.UpgradedWithoutAppUpdate:
        this.sendAnalyticsEvent(`modal-${this.modalType}`, 'confirm');
        this.closeModal();
        this.router.transitionTo('apps.app.billing.summary');
        break;
      default:
        this.closeModal();
        break;
    }
  }

  get displayModal() {
    return this.modalType !== '';
  }

  @action
  closeModal() {
    if (taskFor(this.upgradeNow).isRunning) {
      return;
    }
    let object = `modal-${this.modalType}`;
    let action = 'close';
    this.sendAnalyticsEvent(object, action);
    this.modalType = '';
  }

  private fireConfettiAnimation() {
    if (!this.confettiCanvas) {
      return;
    }
    let confettiObj = confetti.create(this.confettiCanvas, {
      resize: true,
    });
    confettiObj({
      particleCount: 600,
      spread: 300,
      origin: { y: 0.3 },
      ticks: 2000,
      gravity: 2,
    });
  }

  @action
  updateSeatNumber(seatNumber: number) {
    this.seatNumber = seatNumber;
    this.seatsToAddToContract = this.seatNumber - this.currentContractedSeats;

    if (this.seatNumber < this.copilotSeatNumber) {
      this.copilotSeatNumber = this.seatNumber;
      this.updateCopilotSeatNumber(this.seatNumber);
    }
    this.purchaseAnalyticsService.trackEvent({
      action: 'inputed',
      object: 'seat_number_input',
      context: { seat_number: `${this.seatNumber}` },
      place: 'annual-plans-manage-subscription',
    });
  }

  @action
  updateCopilotSeatNumber(inputtedCopilotSeat: number | string) {
    let inputtedCopilotSeatAsNumber = +inputtedCopilotSeat;

    this.copilotSeatNumber = inputtedCopilotSeatAsNumber;
    this.copilotSeatsToAddToContract = this.copilotSeatNumber - this.currentContractedCopilotSeats;
    this.purchaseAnalyticsService.trackEvent({
      action: 'inputed',
      object: 'copilot_seat_number_input',
      context: { seat_number: `${this.copilotSeatNumber}` },
      place: 'annual-plans-manage-subscription',
    });
  }

  @action
  moveOverageSeats() {
    this.seatNumber = this.currentOverageSeats;
    this.seatsToAddToContract = this.seatNumber;
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'move_overage_seats',
      context: { seat_number: `${this.seatNumber}` },
      place: 'annual-plans-manage-subscription',
    });
  }

  @action
  moveOverageCopilotSeats() {
    this.copilotSeatNumber = this.currentCopilotOverageSeats;
    this.copilotSeatsToAddToContract = this.copilotSeatNumber;
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'move_overage_copilot_seats',
      context: { seat_number: `${this.copilotSeatNumber}` },
      place: 'annual-plans-manage-subscription',
    });
  }

  @action
  moveTrialingCopilotSeats() {
    this.copilotSeatNumber = this.adminsWithCopilotSeats;
    this.copilotSeatsToAddToContract = this.copilotSeatNumber;
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'move_trialing_copilot_seats',
      context: { seat_number: `${this.copilotSeatNumber}` },
      place: 'annual-plans-manage-subscription',
    });
  }

  @action setSelected(plan: Plan) {
    // janky AF
    if (!plan.product.addon) {
      if (this.selectedPlanId === plan.idAsNumber) {
        //unset
        this.selectedPlanId = this.inSubscriptionPlan.idAsNumber;
      } else {
        this.selectedPlanId = plan.idAsNumber;
      }
    }
    if (plan.product.addon) {
      if (!this.addOnIdsToAdd?.includes(plan.idAsNumber)) {
        this.addOnIdsToAdd = [...this.addOnIdsToAdd, plan.idAsNumber];
      } else {
        this.addOnIdsToAdd = [...this.addOnIdsToAdd.filter((id) => id !== plan.idAsNumber)];
      }
    }
  }

  @action onOpenSectionChange(sectionId: any) {
    this.openSectionId = sectionId;
  }

  @action onSectionDidOpen(sectionId: any) {
    this.sendAnalyticsEvent(sectionId, 'opened');
  }

  @action onSectionDidClose(sectionId: any) {
    this.sendAnalyticsEvent(sectionId, 'closed');
  }

  @action sendAnalyticsEvent(object: string, action: string) {
    this.purchaseAnalyticsService.trackEvent({
      action,
      object,
      context: 'update-subscription',
      place: 'annual-plans-manage-subscription',
    });
  }

  @dropTask
  *upgradeNow(): TaskGenerator<void> {
    let upgradePlanIds = [this.selectedPlanId, ...this.addOnIdsToAdd].compact();
    if (this.selectedCopilotAddon) {
      upgradePlanIds.push(FIN_AI_COPILOT_BASE_ID_NUM);
    }

    try {
      this.showCloseIcon = false;
      yield this.annualSubscriptionUpdateService.upgrade.perform({
        planIds: upgradePlanIds,
        coreSeats: this.newContractedSeats,
        copilotSeats: this.newContractedCopilotSeats,
      });
      yield this.appService.app.reload();
      //reset local seats value so it doesn't add to the contracted value retrived on success
      this.seatsToAddToContract = 0;
      this.copilotSeatsToAddToContract = 0;
      yield this.store.findRecord('billing/contract', this.app.id, { reload: true });
      this.notificationsService.notifyConfirmation(
        this.intl.t('billing.annual-manage-subscription.upgrade-success'),
      );
      this.purchaseAnalyticsService.trackEvent({
        action: 'clicked',
        object: 'upgrade-button',
        context: 'update-subscription',
        place: 'annual-plans-manage-subscription',
        upgrade_info: {
          current_plan_id: this.inSubscriptionPlan.idAsNumber,
          current_add_on_ids: this.currentAddonIds,
          current_seat_number: this.currentContractedSeats,
          current_overage_seats: this.currentOverageSeats,
          upgrade_plan_id: this.selectedPlanId,
          additional_add_on_ids: this.addOnIdsToAdd,
          additional_seat_number: this.seatNumber,
        },
      });

      this.showConfettiCanvas = true;
      setTimeout(() => {
        this.fireConfettiAnimation();
        this.closeModal();
      }, ENV.APP._200MS);
    } catch (error) {
      console.error('Error upgrading subscription', error);
      this.notificationsService.notifyError(error.jqXHR.responseJSON.error);
    }
  }

  @keepLatestTask
  *getQuote() {
    let corePlanIds = this.availablePlans.map((plan: Plan) => plan.idAsNumber);
    let planIds = [...corePlanIds, PROACTIVE_SUPPORT_PLUS_BASE_ID_NUM, FIN_AI_COPILOT_BASE_ID_NUM];
    let params = planIds.map((id) => ({
      ...DEFAULT_REQUEST_PARAMS,
      planIds: [id],
      source: 'pricing-five-annual-upgrade',
    }));

    yield taskFor(this.quoteService.getQuotes).perform(params);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::AnnualManageSubscription::SubscriptionBuilder': typeof SubscriptionBuilder;
    'billing/annual-manage-subscription/subscription-builder': typeof SubscriptionBuilder;
  }
}
