/* import __COLOCATED_TEMPLATE__ from './profiles.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import {
  objectTypes,
  matchBehaviors,
  humanReadableObjectNames,
} from 'embercom/models/data/matching-system/matching-constants';
import { EVERYONE_PREDICATE } from 'embercom/lib/operator/custom-bots/constants';
import { statisticKeys } from 'embercom/models/data/outbound/constants';

interface Args {
  profiles: $TSFixMe[];
  totalLiveFinProfileCount: number;
}
export default class Profiles extends Component<Args> {
  @service outboundHomeService: $TSFixMe;
  @service appService: $TSFixMe;
  @service router: $TSFixMe;
  @service intl: $TSFixMe;
  @service store: $TSFixMe;
  @service notificationsService: $TSFixMe;

  constructor(owner: unknown, args: $TSFixMe) {
    super(owner, args);
  }

  get profiles() {
    return this.args.profiles;
  }

  get statisticKeys() {
    return statisticKeys;
  }

  get isAddProfileOptionDisabled() {
    return (
      this.appService.app.canUseFeature('workflows_core_billing_feature') &&
      !this.appService.app.answerBotProduct &&
      !this.appService.app.canUseFeature('self-serve-support-disable-profile-creation-killswitch')
    );
  }

  @action
  async reorder(updatedPriorities: $TSFixMe, draggedProfile: $TSFixMe) {
    let orderIsChanged = false;
    for (let i = 0; i < updatedPriorities.length - 1; i++) {
      let current = updatedPriorities[i];
      let next = updatedPriorities[i + 1];
      if (current['priority'] > next['priority']) {
        orderIsChanged = true;
        break;
      }
    }
    if (!orderIsChanged) {
      return;
    }

    try {
      this.profiles.map((profile) => {
        profile['priority'] = updatedPriorities.indexOf(profile);
      });

      let ruleset = await this.store.findRecord(
        'matching-system/ruleset',
        draggedProfile.contentWrapperId,
      );
      let draggedProfileIndex: number = updatedPriorities.indexOf(draggedProfile);
      let previousProfile = updatedPriorities[draggedProfileIndex - 1];
      let nextProfile = updatedPriorities[draggedProfileIndex + 1];

      let response = await ruleset.reorder(previousProfile, nextProfile);

      // Map ruleset id to priority.
      let rulesetPriorityMapping = response.reduce((map: $TSFixMe, ruleset: $TSFixMe) => {
        map[ruleset.ruleset_id] = ruleset.priority;
        return map;
      }, {});

      // Update the content wrappers in the store to have the correct priority
      // from the backend response, or clean them up if we don't get them in
      // the response.
      this.store.peekAll('outbound/content-wrapper').forEach((wrapper: $TSFixMe) => {
        let id = wrapper.contentWrapperId;
        if (id in rulesetPriorityMapping) {
          wrapper.set('priority', rulesetPriorityMapping[id]);
        } else {
          wrapper.rollbackAttributes();
        }
      });

      // Store the new priorities in the store.
      this.store.pushPayload({
        'outbound/content-wrapper': this.store
          .peekAll('outbound/content-wrapper')
          .filter((wrapper: $TSFixMe) => wrapper.hasDirtyAttributes)
          .map((wrapper: $TSFixMe) => wrapper.toJSON({ includeId: true })),
      });

      this.notificationsService.notifyConfirmation(
        `The priority of your ${
          humanReadableObjectNames[this.outboundHomeService.activeList]
        } has been reordered`,
      );
    } catch {
      this.notificationsService.notifyError(`Something went wrong. Please try again later.`);

      // If there were any errors, let's rollback all the content wrappers
      // that were updated so that they aren't dirty.
      this.profiles
        .filter((profile) => profile.hasDirtyAttributes)
        .forEach((profile) => profile.rollbackAttributes());
    }
  }

  @action
  createNewProfile() {
    let clientData =
      this.profiles.length <= 1
        ? { title: this.intl.t('ai-agent.profiles.editor.default-title') }
        : {};
    let rulesetParams = {
      app_id: this.appService.app.id,
      object_type: objectTypes.resolutionBotBehavior,
      match_behavior: matchBehaviors.transient,
      object_data: {},
      role_predicate_group: { predicates: [EVERYONE_PREDICATE] },
      client_data: clientData,
    };

    this.router.transitionTo('apps.app.content.new', rulesetParams);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AiAgent::Profiles': typeof Profiles;
  }
}
