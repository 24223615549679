/* import __COLOCATED_TEMPLATE__ from './collection-item.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type TreeItem } from 'embercom/objects/tree-list';
import { MAX_LEVEL } from './collection-tree-helper';
import { type Collection, type DataObject, DataTypes } from './types';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type ArticleMultilingual from 'embercom/models/article-multilingual';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type RouterService from '@ember/routing/router-service';

interface Args {
  item: TreeItem;
}

export default class CollectionItem extends Component<Args> {
  collectionDataObject = this.args.item.dataObject as DataObject;
  @tracked openArticlePicker = false;
  @tracked loading = false;
  @tracked addableArticles: Array<ArticleMultilingual> = [];
  @tracked selectedArticles: Array<any> = [];
  @service declare intl: IntlService;
  @service declare router: RouterService;

  get locale() {
    return this.collectionDataObject.locale();
  }

  get collection() {
    return this.collectionDataObject.content as Collection;
  }

  get collectionStatus() {
    if (!this.collection.name) {
      return 'missing-title';
    }
    if (!this.contentHasPublishedChild(this.args.item)) {
      return 'missing-article';
    }
    return 'published';
  }

  contentHasPublishedChild(item: TreeItem): boolean {
    if (!item.children?.length) {
      return false;
    }
    return item.children.any((child) => {
      let cData = child.dataObject;
      let articleLocale = cData.locale && cData.locale();
      let selectedLocalizedArticle =
        articleLocale && cData.content?.locales && cData.content?.locales[articleLocale];
      return (
        selectedLocalizedArticle?.state === 'published' || this.contentHasPublishedChild(child)
      );
    });
  }

  @action
  async onAdd(dataType: DataTypes) {
    if (dataType === DataTypes.collection && this.collectionDataObject.addCollection) {
      await this.collectionDataObject.addCollection();
    } else if (dataType === DataTypes.article && this.collectionDataObject.listAddableArticles) {
      this.openArticlePicker = true;
      this.loading = true;
      let addableArticles = await this.collectionDataObject.listAddableArticles();
      this.addableArticles = addableArticles.reject(
        (article: ArticleMultilingual) => article.readOnly,
      );
      this.selectedArticles = [];
      this.loading = false;
    }
  }

  @action
  closeArticlePicker() {
    this.openArticlePicker = false;
  }

  @action
  createArticleInCollection() {
    // we open the article editor in a new tab
    let urlForCreatingArt = this.router.urlFor('apps.app.articles.articles.new', {
      queryParams: { collection_id: this.collection.id },
    });
    safeWindowOpen(urlForCreatingArt, '_blank');
  }

  get isAddToCollectionButtonDisabled() {
    return this.loading || !this.selectedArticles.length;
  }

  get addToCollectionButtonLabel() {
    return this.selectedArticles.length
      ? this.intl.t('articles.collections.add_article.button', {
          count: this.selectedArticles.length,
        })
      : this.intl.t('articles.collections.add_article.default_button');
  }

  @action
  async addSelectedArticleToCollection() {
    this.loading = true;
    if (this.collectionDataObject.addArticlesToCollection) {
      await this.collectionDataObject.addArticlesToCollection(this.selectedArticles);
    }
    this.loading = false;
    this.openArticlePicker = false;
  }

  @action
  async removeCollection() {
    if (this.collectionDataObject.deleteCollection) {
      await this.collectionDataObject.deleteCollection();
    }
  }

  @action
  async saveCollection() {
    if (this.collectionDataObject.saveCollection) {
      await this.collectionDataObject.saveCollection();
    }
  }

  // Toggle isExpanded unless the click is on an element preventing expansion
  @action
  toggleIsExpanded(event: PointerEvent) {
    let preventExpandElements = Array.from(
      document.getElementsByClassName('prevent-colleciton-tree-expand'),
    );
    let target = event.target;

    if (!(target instanceof Element)) {
      return;
    }

    for (let element of preventExpandElements) {
      if (element.contains(target)) {
        return;
      }
    }

    this.args.item.isExpanded = !this.args.item.isExpanded;
  }

  get contents() {
    let items = [
      {
        text: this.intl.t('articles.collections.actions.add_articles'),
        value: DataTypes.article,
        icon: 'article',
      },
    ];
    if (this.collectionDataObject.level < MAX_LEVEL) {
      items.push({
        text: this.intl.t('articles.collections.actions.add_child_collection'),
        value: DataTypes.collection,
        icon: 'collection',
      });
    }
    return [
      {
        items,
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Collections::Tree::CollectionItem': typeof CollectionItem;
    'articles/site/collections/tree/collection-item': typeof CollectionItem;
  }
}
