/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-phone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Calling from 'embercom/models/settings/calling';
import type CardlessTrialService from 'embercom/services/cardless-trial-service';
import type IntercomCallService from 'embercom/services/intercom-call-service';
import { PHONE_FREE_USAGE_EXPERIMENT } from 'embercom/services/experiment-opt-in-service';
import { taskFor } from 'ember-concurrency-ts';
import type Store from '@ember-data/store';

interface Args {
  settings: Calling;
  isCallingSupported: boolean;
  isFromNewSettings?: boolean;
}

export default class CallingSettings extends Component<Args> {
  @service declare cardlessTrialService: CardlessTrialService;
  @service declare intercomCallService: IntercomCallService;
  @service declare experimentOptInService: any;
  @service declare store: Store;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.intercomCallService.registerCallEvent({
      action: 'viewed',
      object: 'calling_settings',
      place: 'calling_settings',
      section: 'settings_page',
    });

    this.enrollInFreeUsageExperiment();
  }

  get trialCreditBalance() {
    return Number(this.args.settings.trialCreditBalance).toFixed(2);
  }

  async enrollInFreeUsageExperiment() {
    await taskFor(this.experimentOptInService.registerForExperiment).perform(
      PHONE_FREE_USAGE_EXPERIMENT,
    );
    let callingSettings = await this.store.queryRecord('settings/calling', {});

    this.intercomCallService.callingEventMetadata = {
      current_pricing_plan: callingSettings?.currentPricingPlan,
      paid_phone_numbers_present: callingSettings?.paidPhoneNumbersPresent,
      trial_phone_numbers_present: callingSettings?.trialPhoneNumbersPresent,
      free_usage_trial_ongoing: callingSettings?.freeUsageTrialOngoing,
      free_usage_experiment_group: callingSettings?.freeUsageExperimentGroup,
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calling::Settings': typeof CallingSettings;
    'calling/settings': typeof CallingSettings;
  }
}
