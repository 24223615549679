/* import __COLOCATED_TEMPLATE__ from './help-center-preview.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import templateOnlyComponent from '@ember/component/template-only';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { type PreviewType } from './react-preview';

interface Signature {
  Args: {
    site: HelpCenterSite;
    media: $TSFixMe;
    previewType: PreviewType;
    setPreviewType: (type: PreviewType) => void;
  };
}

const HelpCenterPreview = templateOnlyComponent<Signature>();
export default HelpCenterPreview;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Articles::Site::Settings::HelpCenterPreview': typeof HelpCenterPreview;
  }
}
